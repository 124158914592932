import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;

  constructor( private formBuilder: FormBuilder, private httpClient: HttpClient,
              private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      login: '',
      password: ''
    });
  }

  enviar() {
    let data = this.form.getRawValue();
    data.password = null;
    this.authService.login(data)
      .subscribe(
        res => {
          // localStorage.setItem('token', res.recordset[0].token);
          sessionStorage.setItem('token', res.recordset[0].token);
          sessionStorage.setItem('nombre_usuario', res.recordset[0].nombre);
          sessionStorage.setItem('apellido_usuario', res.recordset[0].apellido);
          this.authService.nombre_usuario_logueado = res.recordset[0].nombre;
          this.authService.apellido_usuario_logueado = res.recordset[0].apellido;
          this.router.navigate(['/']);
        },
        err => {
          let mensaje = err.error.error ? err.error.error : err.error.mensaje;
          alert(mensaje);
        });
  }

}
