import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CargaMapaComponent } from './componentes/carga-mapa/carga-mapa.component';
import { CargaMapa2Component } from './componentes/carga-mapa2/carga-mapa2.component';
import { CargaComponent } from './componentes/carga/carga.component';
import { HomeComponent } from './componentes/home/home.component';
import { LoginComponent } from './componentes/login/login.component';
import { OlmapaComponent } from './componentes/olmapa/olmapa.component';
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'visor',
    component: OlmapaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'carga',
    component: CargaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'carga_mapa',
    component: CargaMapaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'carga_mapa_unico',
    component: CargaMapa2Component,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
