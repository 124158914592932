// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  name: "default", //dafault
  titulo: 'Nazca Soluciones - Floridablanca',
  wms: 'http://129.213.157.119:8083/geoserver/ws005_floridablanca/wms',
  url_api: 'http://129.213.56.178:8070/Api/',
  url_menu: 'https://apps.nazcacatastro.com/ords/floridablanca/ow_gral/seguridad/menus/MENUMAPA',
  mapa_base: 'ws005_floridablanca:BaseCatastralFloridablancaGeonode',
capas: [
{
  nombre: 'OpenStreetMap',
},
{
  nombre: 'BaseCatastralFloridablancaGeonode',
}
],
  menus: [
    {
      nombre: 'Consulta Catastral',
      path: '/visor',
      descripcion: "En la consulta catastral podrá buscar por información relaionada a predios o propietarios ......",
    },
    {
      nombre: 'Solicitud Carga y Descarga',
      path: '/carga',
      descripcion: "En carga y descarga de información podrá cargar y descargar la información relacionada ......",
    },
    {
      nombre: 'Otro Menú',
      path: '/otro',
      descripcion: "En otro menú podrá -..... la información relacionada ......",
    },
  ],
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
