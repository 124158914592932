import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComunService {
  private ulr_backend = environment.url_api;

  constructor(
    private httpClient: HttpClient
  ) { }

  consultar_depatramentos() {
    return this.httpClient.get <any>(
      this.ulr_backend + 'Comun/obtenerDepartamentos',
    );
  }


  consultar_municipios(departamento_id: number) {
    return this.httpClient.get <any>(
      this.ulr_backend + `Comun/obtenerMunicipios/${departamento_id}`,
    );
  }


  consultar_tipo_documentos() {
    return this.httpClient.get <any>(
      this.ulr_backend + 'Comun/obtenerTiposIdentificacion',
    );
  }


  consultar_por_predio(datos_predio: any) {
    let datos = {
      id_Predio: datos_predio.id_predio,
      departamento: parseInt(datos_predio.departamento),
      municipio: parseInt(datos_predio.municipio),
      matricula_Inmobiliaria: datos_predio.matricula_inmobiliaria,
      numero_Predial: datos_predio.numero_predial,
      nupre: datos_predio.nupre,
      numero_Predial_Anterior: datos_predio.numero_predial_anterior
    }

    return this.httpClient.post <any>(
      this.ulr_backend + 'Consultas/consultaXPredio',
      datos
    );
  }


  consultar_por_propietario(datos_propietario: any) {
    let datos = {
      tipo_Documento: parseInt(datos_propietario.tipo_documento),
      documento_Identidad: datos_propietario.numero_documento
    }

    return this.httpClient.post <any>(
      this.ulr_backend + 'Consultas/consultaXPropietario',
      datos
    );
  }


  // consultar_menus() {
  //   return this.httpClient.get <any>(
  //     this.ulr_backend + 'Comun/obtenerMenu/MENUMAPA',
  //   );
  // }


  consultar_solicitudes() {
    return this.httpClient.get <any>(
      this.ulr_backend + 'Solicitudes/consultaSolicitudesXUsuario',
    );
  }


  descargar_cartografia(datos_solicitud: any, tipoArchivo: string) {
    let datos = {
      "idPredio": parseInt(datos_solicitud.idPredio),
      "numeroSolicitud": parseInt(datos_solicitud.solinume),
      "mutacion": parseInt(datos_solicitud.mutacion),
      "outputFormat": tipoArchivo
    }

    return this.httpClient.post <any>(
      this.ulr_backend + 'Solicitudes/DescargarCartografia',
      datos
    );

  }

  subir_cartografia(datos_archivos: any, numeroSoliciud: number, tipoArchivo: string) {
    let datos = {
      "listaFiles": datos_archivos,
      "inputFormat": tipoArchivo,  //Formato del archivo a cargar
      "numeroSolicitud": numeroSoliciud
    }

    return this.httpClient.post <any>(
      this.ulr_backend + 'Solicitudes/SubirCartografia',
      datos
    );
  }


  obtener_geojson_original(data) {
    let datos = {
      "idPredio": data.idPredio,
      "numeroSolicitud": data.solinume,
      "mutacion": parseInt(data.mutacion),
    }

    return this.httpClient.post <any>(
      this.ulr_backend + 'Validaciones/obtenerGeoJsonOriginales',
      datos
    );
  }


  obtener_geojson_delta(data) {
    let datos = {
      "idPredio": data.idPredio,
      "numeroSolicitud": data.solinume
    }

    return this.httpClient.post <any>(
      this.ulr_backend + 'Validaciones/obtenerGeoJsonDelta',
      datos
    );
  }


  validar_datos(data) {
    let datos = {
      "idPredio": data.idPredio,
      "numeroSolicitud": data.solinume,
      mutacion: parseInt(data.mutacion),
    }

    return this.httpClient.post <any>(
      this.ulr_backend + 'Validaciones/validarGeometriaDelta',
      datos
    );
  }


  procesar_datos(data) {
    let datos = {
      "idPredio": data.idPredio,
      "numeroSolicitud": data.solinume,
      mutacion: parseInt(data.mutacion),
    }

    return this.httpClient.post <any>(
      this.ulr_backend + 'Validaciones/procesarSolicitud',
      datos
    );
  }

}
