import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private ulr_backend = environment.url_api;
  private _nombre_usuario_logueado?: string;
  private _apellido_usuario_logueado?: string;

  constructor( private httpClient: HttpClient, private router: Router) { }

  get nombre_usuario_logueado(): string {
    return this._nombre_usuario_logueado;
  }
  set nombre_usuario_logueado(nombre: string) {
      this._nombre_usuario_logueado = nombre;
  }

  get apellido_usuario_logueado(): string {
    return this._apellido_usuario_logueado;
  }
  set apellido_usuario_logueado(apellido: string) {
      this._apellido_usuario_logueado = apellido;
  }

  login(data: any) {
    return this.httpClient.post <any>(
      this.ulr_backend + 'User/Login',
      data
    );
  }

  logout() {
    // localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('usuario');
    this.router.navigate(['login']);
  }

  esta_logueado() {
    // if (localStorage.getItem('token')) {
    if (sessionStorage.getItem('token')) {
      return true;
    }
    else {
      return false;
    }
  }

  getToken() {
    // return localStorage.getItem('token');
    return sessionStorage.getItem('token');
  }
}
