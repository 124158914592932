import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from "../../servicios/menu.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  menus;
  constructor(
    private menuService: MenuService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.consultar_menus();
  }

  consultar_menus() {
    this.menuService.consultar_menus()
      .subscribe(
        res => {
          this.menus = res.items;
        },
        err => {
          if(err.statusText=='Unauthorized') {
            alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
            this.router.navigate(['/login']);
          }
        }
      );
  }

}
