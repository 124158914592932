<div class="row">

    <div *ngFor="let menu of menus;" class="col-sm-4">
        <div class="card" style="height: 100%;">
        <div class="card-body">
            <h5 class="card-title">{{menu.funcionalidad}}</h5>
            <p class="card-text my-5">{{menu.funcdesc}}</p>
            <a style="position:absolute;bottom:10%;" routerLink="{{menu.funcobje}}" class="btn btn-primary">Acceder</a>
        </div>
        </div>
    </div>
<!--
    <div class="col-sm-4">
        <div class="card">
        <div class="card-body">
            <h5 class="card-title">VISOR</h5>
            <p class="card-text py-5">With supporting text below as a natural lead-in to additional content.</p>
            <a routerLink="/visor" class="btn btn-primary">Ir al Visor</a>
        </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="card">
        <div class="card-body">
            <h5 class="card-title">CONSULTA</h5>
            <p class="card-text py-5">With supporting text below as a natural lead-in to additional content.</p>
            <a href="#" class="btn btn-primary">Ir a la Consulta</a>
        </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="card">
        <div class="card-body">
            <h5 class="card-title">CARGA DE INFORMACIÓN</h5>
            <p class="card-text py-5">With supporting text below as a natural lead-in to additional content.</p>
            <a href="#" class="btn btn-primary">Cargar Información</a>
        </div>
        </div>
    </div> -->
</div>
