import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ComunService } from "../../servicios/comun.service";
import { environment } from 'src/environments/environment';

// OL
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';
import Overlay from 'ol/Overlay';
import { toLonLat } from 'ol/proj';
import { fromLonLat } from 'ol/proj';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

// Controles
import { ScaleLine, defaults as defaultControls } from 'ol/control';

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Definición de $
const $ = require( "jquery" );

@Component({
  selector: 'app-carga-mapa2',
  templateUrl: './carga-mapa2.component.html',
  styleUrls: ['./carga-mapa2.component.css']
})
export class CargaMapa2Component implements OnInit {

  map1: Map;
  popupOverlay;
  localizacion_coordenadas;

  capas = [];
  capas1 = [];
  capas2 = [];

  solicitud: any;
  geojson_original;
  geojson_delta;
  datos_validos;
  slider_value_array = new Array();
  slider_value_array_1 = new Array();
  slider_value_array_2 = new Array();
  button_checked = new Array();
  button_checked_1 = new Array();
  button_checked_2 = new Array();

  datos_validacion;
  datos_procesar;
  @ViewChild("detalles_error") modalContent: TemplateRef<any>;
  @ViewChild("detalles_procesar") modalContent_procesar: TemplateRef<any>;

  constructor(
    private comunService: ComunService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.solicitud = JSON.parse(sessionStorage.getItem('solicitud'));
    this.datos_validos = false;
    this.inicializa_mapa();
    this.obtener_geojson_original();
    this.obtener_geojson_delta();
    let i = 0;
    while (i < 10) {
      this.slider_value_array[i] = '1';
      this.slider_value_array_1[i] = '1';
      this.slider_value_array_2[i] = '1';
      this.button_checked[i] = true;
      this.button_checked_1[i] = true;
      this.button_checked_2[i] = true;
      i++;
    }
    let sidebar = $('#mySidebar1');
    $(".form-control-range").each(function() {
      $( this ).width( sidebar.width() * 0.8 );
    });
  }

  obtener_geojson_delta() {
    this.comunService.obtener_geojson_delta(this.solicitud)
    .subscribe(
      res => {
        if(this.geojson_original) {
          $("#cargando").hide();
        }
        this.geojson_delta = res.recordset[0];
        if (this.geojson_delta) {
          this.mostrar_geojson_delta(this.geojson_delta);
        }
        else {
          alert('No hay GeoJson delta para mostrar');
        }
      },
      err => {
        $("#cargando").hide();
        if(err.statusText=='Unauthorized') {
          alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
          this.router.navigate(['/login']);
        }
        else{
          let mns_err = err.error.mensaje ? err.error.mensaje : err.error.error;
          alert(mns_err);
        }
      }
    );
  }

  obtener_geojson_original() {
    this.comunService.obtener_geojson_original(this.solicitud)
    .subscribe(
      res => {
        if(this.geojson_delta) {
          $("#cargando").hide();
        }
        this.geojson_original = res.recordset[0];

        if (this.geojson_original) {
          this.mostrar_geojson_originales(this.geojson_original);
        } else {
          alert('No hay GeoJson originales para mostrar');
        }
      },
      err => {
        $("#cargando").hide();
        if(err.statusText=='Unauthorized') {
          alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
          this.router.navigate(['/login']);
        } else{
          let mns_err = err.error.mensaje ? err.error.mensaje : err.error.error;
          alert(mns_err);
        }
      }
    );
  }

  inicializa_mapa() {
    // ########### MAPA ###########
    let view1 = new View({
      center: fromLonLat([-74.08175, 4.60971]),
      zoom: 10
    });

    let capa1 = new TileLayer({
      source: new OSM()
    })
    capa1.set('nombre', 'Open Street Maps');

    // ########### Control scala ###########
    let scaleLinecontrol1 = new ScaleLine({
      units: 'metric',
    });

    // ORIGINAL
    this.map1 = new Map({
      target: 'map1',
      view: view1,
      layers: [ capa1 ],
      controls: defaultControls().extend([
        scaleLinecontrol1
      ]),
    });

    let mapa_base_catastral_wms_source1 = new ImageWMS({
      ratio: 1,
      url: environment.wms,
      params: {
        'FORMAT': 'image/png',
        'VERSION': '1.1.1',
        "STYLES": '',
        "LAYERS": environment.mapa_base,
        "exceptions": 'application/vnd.ogc.se_inimage',
      }
    });
    let mapa_base_catastral_layer1 = new ImageLayer({
      source: mapa_base_catastral_wms_source1
    });
    mapa_base_catastral_layer1.set('nombre', 'Mapa Base Catastral');
    this.map1.addLayer(mapa_base_catastral_layer1);

    // ########### Pop UP ###########
    let container1 = $('#popup1');
    let content1 = document.getElementById('popup-content1');
    let closer1 = document.getElementById('popup-closer1');

    // Create an overlay to anchor the popup to the map.
    let overlay1 = new Overlay({
      element: container1[0],
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    this.map1.addOverlay(overlay1);

    closer1.onclick = function () {
      overlay1.setPosition(undefined);
      closer1.blur();
      return false;
    };

    // ########### Eventos mapas ###########
    this.map1.on('singleclick', (evt) => {
      this.mostrar_popup(evt, this.map1, overlay1, content1);
    });

    this.map1.on('rendercomplete', e => {
      this.capas = [];
      this.capas1 = [];
      this.capas2 = [];

      this.map1.getLayers().forEach((layer) => {
        let capa_actual = {nombre: layer.getProperties().nombre};

        // CAPAS ORIGINAL
        if (layer.getProperties().nombre.includes('Original')) {
          let ya_esta = this.capas1.some(capa => capa.nombre === capa_actual.nombre);
          if (!ya_esta) {
            this.capas1.push({nombre: layer.getProperties().nombre});
          }
        }

        // CAPAS DELTA
        else if (layer.getProperties().nombre.includes('Delta')) {
          let ya_esta = this.capas2.some(capa => capa.nombre === capa_actual.nombre);
          if (!ya_esta) {
            this.capas2.push({nombre: layer.getProperties().nombre});
          }
        }

        // CAPAS BASE
        else {
          let ya_esta = this.capas.some(capa => capa.nombre === capa_actual.nombre);
          if (!ya_esta) {
            this.capas.push({nombre: layer.getProperties().nombre});
          }
        }
      });
    });
  }

  sidebar1() {
    if ( $("#mySidebar1").is(':visible')) {
      $("#mySidebar1").hide(500);
    }
    else {
      $("#mySidebar1").show(500);
    }
  }

  capa_boton(capa, i) {
    this.map1.getLayers().forEach((layer, layer_index) => {
      if (layer.getProperties()['nombre'] == capa.nombre) {
        layer.setVisible(!layer.getVisible());
        if (capa.nombre.includes('Original')) {
          this.button_checked_1[i] = layer.getVisible();
        } else if (capa.nombre.includes('Delta')) {
          this.button_checked_2[i] = layer.getVisible();
        } else {
          this.button_checked[i] = layer.getVisible();
        }
      }
    });
  }

  mostrar_popup(evt, map, overlay, content){
    //let map = m == 1 ? this.map1 : this.map1;
    let coordinate = evt.coordinate;
    let contenido_popup = `<table class="table table-bordered  table-hover">
      <tbody>`;
      map.forEachFeatureAtPixel(evt.pixel, (feat) => {
        if('datos_construccion_d' in feat.getProperties()) {
          let datos = feat.get('datos_construccion_d');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Construcción Delta</th>
              </tr>
              <tr>
                <th>ID Construcción</th>
                <td>${datos.construccionID}</td>
              </tr>
              <tr>
                <th>Número pisos</th>
                <td>${datos.numero_pisos_construccion}</td>
              </tr>
              <tr>
                <th>Número sotanos</th>
                <td>${datos.numero_sotanos_construccion}</td>
              </tr>
              <tr>
                <th>Número mezanines</th>
                <td>${datos.numero_mezanines_construccion}</td>
              </tr>
              <tr>
                <th>Año construcción</th>
                <td>${datos.anio_construccion}</td>
              </tr>
              <tr>
                <th>Número semisotanos</th>
                <td>${datos.numero_semisotanos_construccion}</td>
              </tr>
              <tr>
                <th>Altura</th>
                <td>${datos.altura}</td>
              </tr>
              <tr>
                <th>conscoco</th>
                <td>${datos.conscoco}</td>
              </tr>
              <tr>
                <th>consarju</th>
                <td>${datos.consarju}</td>
              </tr>`;
        }
        if('datos_construccion_o' in feat.getProperties()) {
          let datos = feat.get('datos_construccion_o');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Construcción Original</th>
              </tr>
              <tr>
                <th>ID Construcción</th>
                <td>${datos.construccionID}</td>
              </tr>
              <tr>
                <th>Número pisos</th>
                <td>${datos.numero_pisos_construccion}</td>
              </tr>
              <tr>
                <th>Número sotanos</th>
                <td>${datos.numero_sotanos_construccion}</td>
              </tr>
              <tr>
                <th>Número mezanines</th>
                <td>${datos.numero_mezanines_construccion}</td>
              </tr>
              <tr>
                <th>Año construcción</th>
                <td>${datos.anio_construccion}</td>
              </tr>
              <tr>
                <th>Número semisotanos</th>
                <td>${datos.numero_semisotanos_construccion}</td>
              </tr>
              <tr>
                <th>Altura</th>
                <td>${datos.altura}</td>
              </tr>
              <tr>
                <th>conscoco</th>
                <td>${datos.conscoco}</td>
              </tr>
              <tr>
                <th>consarju</th>
                <td>${datos.consarju}</td>
              </tr>`;
        }
        if('datos_terreno_d' in feat.getProperties()) {
          let datos = feat.get('datos_terreno_d');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Terreno Delta</th>
              </tr>
              <tr>
                <th>ID Terreno</th>
                <td>${datos.terrenoID}</td>
              </tr>
              <tr>
                <th>Etiqueta terreno</th>
                <td>${datos.etiqueta_terreno}</td>
              </tr>
              <tr>
                <th>terrcote</th>
                <td>${datos.terrcote}</td>
              </tr>
              <tr>
                <th>terrarju</th>
                <td>${datos.terrarju}</td>
              </tr>`;
        }
        if('datos_terreno_o' in feat.getProperties()) {
          let datos = feat.get('datos_terreno_o');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Terreno Original</th>
              </tr>
              <tr>
                <th>ID Terreno</th>
                <td>${datos.terrenoID}</td>
              </tr>
              <tr>
                <th>Etiqueta terreno</th>
                <td>${datos.etiqueta_terreno}</td>
              </tr>
              <tr>
                <th>terrcote</th>
                <td>${datos.terrcote}</td>
              </tr>
              <tr>
                <th>terrarju</th>
                <td>${datos.terrarju}</td>
              </tr>`;
        }
        if('datos_manzana_d' in feat.getProperties()) {
          let datos = feat.get('datos_manzana_d');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Manzana Delta</th>
              </tr>
              <tr>
                <th>ID Manzana</th>
                <td>${datos.manzanaID}</td>
              </tr>
              <tr>
                <th>Código manzana</th>
                <td>${datos.codigoManzana}</td>
              </tr>`;
        }
        if('datos_manzana_o' in feat.getProperties()) {
          let datos = feat.get('datos_manzana_o');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Manzana Original</th>
              </tr>
              <tr>
                <th>ID Manzana</th>
                <td>${datos.manzanaID}</td>
              </tr>
              <tr>
                <th>Código manzana</th>
                <td>${datos.codigoManzana}</td>
              </tr>`;
        }
        if('datos_puntolindero_d' in feat.getProperties()) {
          let datos = feat.get('datos_puntolindero_d');
          contenido_popup += `
            <tr>
                <th colspan="2" class="text-center">Punto lindero Delta</th>
            </tr>
            <tr>
              <th>ID Lindero</th>
              <td>${datos.linderoID}</td>
            </tr>`;
        }
        if('datos_puntolindero_o' in feat.getProperties()) {
          let datos = feat.get('datos_puntolindero_o');
          contenido_popup += `
            <tr>
                <th colspan="2" class="text-center">Punto lindero Original</th>
            </tr>
            <tr>
              <th>ID Lindero</th>
              <td>${datos.linderoID}</td>
            </tr>`;
        }
        if('datos_unidadconstruccion_d' in feat.getProperties()) {
          let datos = feat.get('datos_unidadconstruccion_d');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Unidad contruccion Delta</th>
              </tr>
              <tr>
                <th>ID Unidad Construcción</th>
                <td>${datos.unidadConstruccionID}</td>
              </tr>`;
        }
        if('datos_unidadconstruccion_o' in feat.getProperties()) {
          let datos = feat.get('datos_unidadconstruccion_o');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Unidad contruccion Original</th>
              </tr>
              <tr>
                <th>ID Unidad Construcción</th>
                <td>${datos.unidadConstruccionID}</td>
              </tr>`;
        }
        if('datos_servidumbre_d' in feat.getProperties()) {
          let datos = feat.get('datos_servidumbre_d');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Servidumbre transito Original</th>
              </tr>
              <tr>
                <th>ID Servidumbre</th>
                <td>${datos.servidumbreID}</td>
              </tr>`;
        }
        if('datos_servidumbre_o' in feat.getProperties()) {
          let datos = feat.get('datos_servidumbre_o');
          contenido_popup += `
              <tr>
                <th colspan="2" class="text-center">Servidumbre transito Delta</th>
              </tr>
              <tr>
                <th>ID Servidumbre</th>
                <td>${datos.servidumbreID}</td>
              </tr>`;
        }        
      },
      {
        hitTolerance: 5,
      });

      contenido_popup += `
              <tr>
                <th>COORDENADAS</th>
                <td>${toLonLat(coordinate)[0]}, ${toLonLat(coordinate)[1]}</td>
              </tr>
            </tbody>
          </table>`;
      content.innerHTML = contenido_popup;
      overlay.setPosition(coordinate);
  }

  agregar_capa_datos(map, nombre, titulo, data, geoJson, style, hasExtent){
    let format = new GeoJSON({
      featureProjection:"EPSG:3857"
    });

    let makeExtent = hasExtent;

    if (geoJson) {
      try{
        let parseGeoJson = JSON.parse(geoJson);
        let features_layer = format.readFeatures(parseGeoJson);

        features_layer[0].set(nombre, data);

        let layerSource = new VectorSource({
          features: features_layer
        });        
        let layerObject = new VectorLayer({
          style: style,
          source: layerSource,
        });
        layerObject.set('nombre', titulo);
        map.addLayer(layerObject);

        if(hasExtent == false){
          let extent = layerSource.getExtent();
          this.map1.getView().fit(extent);

          makeExtent = true;
        }
      }
      catch (error) {
        let messageError = `Se ha encontrado un error al agregar ${titulo} al mapa - ${error}`
        alert(messageError);
        throw messageError;
      }
    }

    return makeExtent;
  }

  mostrar_geojson_originales(data) {
    let style_manzana = new Style({
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 1)',
        width: 2
      }),
      fill: new Fill({
        color: [255, 0, 0, 0.5]
      })
    });
    let style_terreno = new Style({
      stroke: new Stroke({
        color: 'rgba(255, 197, 0, 1)',   //'orange',      //#ffc500
        width: 2
      }),
      fill: new Fill({
        color: [255, 197, 0, 0.5]   //#ffc500 rgba(255, 197, 0, 1)
      })
    });
    let style_construccion = new Style({
      stroke: new Stroke({
        color: 'rgba(0, 0, 255, 1)',
        width: 1
      }),
      fill: new Fill({
        color: [0, 0, 255, 0.5]
      })
    });
    let style_puntolindero = new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({color: '#444444'}),
        stroke: new Stroke({color: '#bada55', width: 1}),
      })
    });
    let style_unidadconstruccion = new Style({
      stroke: new Stroke({
        color: 'rgba(74, 54, 32, 1)',   // 'brown',
        width: 1
      }),
      fill: new Fill({
        color: [74, 54, 32, 0.5]
      })
    });
    let style_servidumbre = new Style({
      stroke: new Stroke({
        color: 'brown',
        width: 1
      }),
      fill: new Fill({
        color: [0, 255, 255, 0.5]
      })
    });

    //Este atributo es para saber si hace extent al mapa o no, el extent se hará a la primera geometría que encuentre
    //una vez lo haga, se da extablece en true para constantar que ya se hizo el extent
    let hasExtent = false;

    // Manzana
    hasExtent = this.agregar_capa_datos(this.map1, 'datos_manzana_o', 'Manzana Original', data, data.geojsonManzana, style_manzana, hasExtent);
    
    // Terreno    
    for (let terreno of data.terreno) {
      hasExtent = this.agregar_capa_datos(this.map1, 'datos_terreno_o', 'Terreno Original', terreno, terreno.geojsonTerreno, style_terreno, hasExtent);
    }

    // Construccion
    for (let construccion of data.construcciones) {
      hasExtent = this.agregar_capa_datos(this.map1, 'datos_construccion_o', 'Construcción Original', construccion, construccion.geojsonConstruccion, style_construccion, hasExtent);
    }

    // PuntoLindero
    for (let puntolindero of data.puntoLindero) {
      hasExtent = this.agregar_capa_datos(this.map1, 'datos_puntolindero_o', 'Punto Lindero Original', puntolindero, puntolindero.geojsonPuntoLindero, style_puntolindero, hasExtent);
    }

    // UnidadConstruccion
    for (let unidadconstruccion of data.unidadConstruccion) {
      hasExtent = this.agregar_capa_datos(this.map1, 'datos_unidadconstruccion_o', 'Unidad Construcción Original', unidadconstruccion, unidadconstruccion.geojsonUnidadConstruccion, style_unidadconstruccion, hasExtent);
    }

    // Servidumbre
    for (let servidumbre of data.servidumbre) {
      this.agregar_capa_datos(this.map1, 'datos_servidumbre_o', 'Servidumbre Transito Delta', servidumbre, servidumbre.geojsonServidumbre, style_servidumbre, true);
    }
  } 

  mostrar_geojson_delta(data) {
    let style_manzana = new Style({
      stroke: new Stroke({
        color: 'rgba(146, 173, 98, 1)',
        width: 2
      }),
      fill: new Fill({
        color: [146, 173, 98, 0.5]
      })
    });

    let style_terreno = new Style({
      stroke: new Stroke({
        color: 'rgba(173, 128, 98, 1)',   //'orange',      //#ffc500
        width: 2
      }),
      fill: new Fill({
        color: [173, 128, 98, 0.5]   //#ffc500 rgba(255, 197, 0, 1)
      })
    });

    let style_construccion = new Style({
      stroke: new Stroke({
        color: 'rgba(43, 43, 43, 1)',
        width: 1
      }),
      fill: new Fill({
        color: [43, 43, 43, 0.5]
      })
    });

    let style_puntolindero = new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({color: '#444444'}),
        stroke: new Stroke({color: '#44dabd', width: 1}),
      })
    });

    let style_unidadconstruccion = new Style({
      stroke: new Stroke({
        color: 'rgba(235, 169, 96, 1)',   // 'yellow',
        width: 1
      }),
      fill: new Fill({
        color: [235, 169, 96, 0.5]
      })
    });

    let style_servidumbre = new Style({
      stroke: new Stroke({
        color: 'yellow',
        width: 1
      }),
      fill: new Fill({
        color: [52, 235, 88, 0.5]
      })
    });

    // Manzana
    this.agregar_capa_datos(this.map1, 'datos_manzana_d', 'Manzana Delta', data, data.geojsonManzana, style_manzana, true);
    
    // Terreno    
    for (let terreno of data.terreno) {
      this.agregar_capa_datos(this.map1, 'datos_terreno_d', 'Terreno Delta', terreno, terreno.geojsonTerreno, style_terreno, true);
    }

    // Construccion
    for (let construccion of data.construcciones) {
      this.agregar_capa_datos(this.map1, 'datos_construccion_d', 'Construcción Delta', construccion, construccion.geojsonConstruccion, style_construccion, true);
    }

    // PuntoLindero
    for (let puntolindero of data.puntoLindero) {
      this.agregar_capa_datos(this.map1, 'datos_puntolindero_d', 'Punto Lindero Delta', puntolindero, puntolindero.geojsonPuntoLindero, style_puntolindero, true);
    }

    // UnidadConstruccion
    for (let unidadconstruccion of data.unidadConstruccion) {
      this.agregar_capa_datos(this.map1, 'datos_unidadconstruccion_d', 'Unidad Construcción Delta', unidadconstruccion, unidadconstruccion.geojsonUnidadConstruccion, style_unidadconstruccion, true);
    }
    
    // Servidumbre
    for (let servidumbre of data.servidumbre) {
      this.agregar_capa_datos(this.map1, 'datos_servidumbre_d', 'Servidumbre Transito Delta', servidumbre, servidumbre.geojsonServidumbre, style_servidumbre, true);
    }
  }


  validar_datos() {
    $("#cargando").show();
    this.comunService.validar_datos(this.solicitud)
      .subscribe( res => {
        $("#cargando").hide();
        if (res.recordset[0].exitoso == 1) {
          this.datos_validos = true;
          alert(res.recordset[0].mensaje);
        }
        else {
          this.datos_validacion = res.recordset[0];
          this.modalService.open(this.modalContent);
        }
      },
      err => {
        $("#cargando").hide();
        if(err.statusText=='Unauthorized') {
          alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
          this.router.navigate(['/login']);
        }
        else{
          let mns_err = err.error.mensaje ? err.error.mensaje : err.error.error;
          alert(mns_err);
        }
      });
  }

  procesar_datos() {
    $("#cargando").show();
    this.comunService.procesar_datos(this.solicitud)
      .subscribe( res => {
        $("#cargando").hide();
        if (res.recordset[0].cambio_flujo_exitoso == true) {
          this.datos_procesar = res.recordset[0];
          this.modalService.open(this.modalContent_procesar);         
          this.router.navigate(['/carga']);
        }
        else {
          alert('No se pudo procesar.');
        }
      },
      err => {
        $("#cargando").hide();
        if(err.statusText=='Unauthorized') {
          alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
          this.router.navigate(['/login']);
        }
        else{
          let mns_err = err.error.mensaje ? err.error.mensaje : err.error.error;
          alert(mns_err);
        }
      });
  }


  change_range(evt, nombre_capa) {
    let op_val = evt.currentTarget.value;
    this.map1.getLayers().forEach(function(layer) {
      if (layer.getProperties()['nombre'] == nombre_capa) {
        layer.setOpacity(parseFloat(op_val));
      }
    });
  }

}
