
<div id="cargando" class="spinner-grow" style="width: 2rem; height: 2rem; position: fixed; z-index: 10;" role="status">
  <span class="sr-only">Loading...</span>
</div>

<!-- Modal -->
<ng-template #detalles_solicitud let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Información de la Solicitud {{solicitud_actual.solinume}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table class="table">
        <tbody>
          <tr>
            <th scope="row">Número de Solicitud</th>
            <td>{{solicitud_actual.solinume}}</td>
          </tr>
          <tr>
            <th scope="row">Actividad en Curso</th>
            <td>{{solicitud_actual.actiActu}}</td>
          </tr>
          <tr>
            <th scope="row">Actividad Anterior</th>
            <td>{{solicitud_actual.actiAnte}}</td>
          </tr>
          <!-- <tr>
            <th scope="row">bpmnAcac</th>
            <td>{{solicitud_actual.bpmnAcac}}</td>
          </tr>
          <tr>
            <th scope="row">bpmnAcan</th>
            <td>{{solicitud_actual.bpmnAcan}}</td>
          </tr>  -->
          <tr>
            <th scope="row">Clase</th>
            <td>{{solicitud_actual.clsodesc}}</td>
          </tr>
          <tr>
            <th scope="row">Tipo</th>
            <td>{{solicitud_actual.tisodesc}}</td>
          </tr>
          <!-- <tr>
            <th scope="row">estagest</th>
            <td>{{solicitud_actual.estagest}}</td>
          </tr> -->
          <tr>
            <th scope="row">Estado</th>
            <td>{{solicitud_actual.estasoli}}</td>
          </tr>
          <tr>
            <th scope="row">Funcionalidad</th>
            <td>{{solicitud_actual.funcionalidad}}</td>
          </tr>
          <tr>
            <th scope="row">Id Predio</th>
            <td>{{solicitud_actual.idPredio}}</td>
          </tr>
          <tr>
            <th scope="row">Canal</th>
            <td>{{solicitud_actual.orsodesc}}</td>
          </tr>
          <tr>
            <th scope="row">Responsable</th>
            <td>{{solicitud_actual.responsable}}</td>
          </tr>
          <!-- <tr>
            <th scope="row">soliclso</th>
            <td>{{solicitud_actual.soliclso}}</td>
          </tr>
          <tr>
            <th scope="row">solidepa</th>
            <td>{{solicitud_actual.solidepa}}</td>
          </tr>
          <tr>
            <th scope="row">soliesge</th>
            <td>{{solicitud_actual.soliesge}}</td>
          </tr>
          <tr>
            <th scope="row">soliesta</th>
            <td>{{solicitud_actual.soliesta}}</td>
          </tr> -->
          <tr>
            <th scope="row">Fecha Actualización Flujo</th>
            <td>{{solicitud_actual.solifeaf}}</td>
          </tr>
          <tr>
            <th scope="row">Fecha Creación Solicitud</th>
            <td>{{solicitud_actual.solifech}}</td>
          </tr>
          <!-- <tr>
            <th scope="row">soliflso</th>
            <td>{{solicitud_actual.soliflso}}</td>
          </tr> -->
          <!-- <tr>
            <th scope="row">solimuni</th>
            <td>{{solicitud_actual.solimuni}}</td>
          </tr>
          <tr>
            <th scope="row">solinpqr</th>
            <td>{{solicitud_actual.solinpqr}}</td>
          </tr> -->
          <tr>
            <th scope="row">Observaciones</th>
            <td>{{solicitud_actual.soliobsv}}</td>
          </tr>
          <!-- <tr>
            <th scope="row">soliorig</th>
            <td>{{solicitud_actual.soliorig}}</td>
          </tr>
          <tr>
            <th scope="row">soliorso</th>
            <td>{{solicitud_actual.soliorso}}</td>
          </tr>
          <tr>
            <th scope="row">soliradi</th>
            <td>{{solicitud_actual.soliradi}}</td>
          </tr>
          <tr>
            <th scope="row">soliresp</th>
            <td>{{solicitud_actual.soliresp}}</td>
          </tr>
          <tr>
            <th scope="row">solitiso</th>
            <td>{{solicitud_actual.solitiso}}</td>
          </tr>
          <tr>
            <th scope="row">solivige</th>
            <td>{{solicitud_actual.solivige}}</td>
          </tr> -->
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Aceptar</button>
    </div>
</ng-template>


<!-- Modal -->
<!-- Cargar archivos -->
<ng-template #cargar_cartografia let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Cargar cartografía para la solictiud {{solicitud_actual.solinume}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="input-group">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="tipo_archivo" id="cargarFGDB" checked value="FILEGDB"  (click)="setTipoArchivoCarga('FILEGDB')">
        <label class="form-check-label" for="cargarFGDB">
          File GDB
        </label>
      </div>
    </div>
    <div class="input-group">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="tipo_archivo" id="cargarSHP" value="SHP" (click)="setTipoArchivoCarga('SHP')" >
        <label class="form-check-label" for="cargarSHP">
          Shapefile
        </label>
      </div>
    </div>
    <div class="input-group mt-3" id="input_group">
      <div class="input-group-prepend">
        <button class="btn btn-sm btn-outline-primary" type="button" (click)="limpiar_archivo(ix)" ><i class="far fa-trash-alt"></i></button>
      </div>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="elementos_input_{{ix}}" (change)="mostrar_nombre($event)" multiple
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
          >
          <label class="custom-file-label" for="elementos_input_{{ix}}" id="label_elementos_input_{{ix}}"></label>
        </div>
        <div class="input-group-append">
          <!-- <button class="btn btn-sm btn-outline-primary" type="button" (click)="enviar_archivo(solicitud, ix)" ><i class="fas fa-file-upload"></i></button> -->
          <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-upload"></i></button>
          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="enviar_archivo(solicitud_actual, ix, 1)">MAPAS PARALELOS</a>
            <div role="separator" class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="enviar_archivo(solicitud_actual, ix, 2)">UN MAPA</a>
          </div>
        </div>
    </div>
    <div id="nombres_archivos_elementos_input_{{ix}}"></div>
    
  </div>
</ng-template>

<div  class="card">
    <div class="card-body" style="padding-left: 0; padding-right: 0;">
      <table class="table table-hover " >
          <thead>
              <tr class="text-center">
                  <th style="width: 4%" >N°</th>
                  <th style="width: 7%" >CLASE</th>
                  <th style="width: 10%" >TIPO</th>
                  <th style="width: 7%" >CANAL</th>
                  <th style="width: 12%" >ACTIVIDAD EN CURSO</th>
                  <th style="width: 3%" >ESTADO</th>
                  <th style="width: 3%" >GESTIÓN</th>
                  <th style="width: 7%" >RESPONSABLE</th>
                  <th style="width: 4%" >FECHA SOLICITUD</th>
                  <th style="width: 5%" >DEPARTAMENTO/ MUNICIPIO</th>
                  <th style="width: 3%" >VER</th>
                  <th style="width: 10%" >DESCARGAR CARTOGRAFÍA</th>
                  <th style="width: 10%">CARGAR CARTOGRAFÍA EDITADA</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let solicitud of solicitudes; index as ix;">
                  <td class="text-center" ><a type="button" class="btn btn-sm btn-success" (click)="openDetailedContent(detalles_solicitud, solicitud)" role="button">{{ solicitud.solinume }}</a></td>
                  <td>{{ solicitud.clsodesc }}</td>
                  <td>{{ solicitud.tisodesc }}</td>
                  <td>{{ solicitud.orsodesc }}</td>
                  <td>{{ solicitud.actiActu }}</td>
                  <td>{{ solicitud.estasoli }}</td>
                  <td>{{ solicitud.estagest }}</td>
                  <td>{{ solicitud.responsable }}</td>
                  <td>{{ solicitud.solifech }}</td>
                  <td>{{ solicitud.solidepa }} / {{ solicitud.solimuni }}</td>
                  <td>
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-map-marked-alt"></i></button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" (click)="mostrar_mapa(solicitud, 1)">Mapas paralelos</a>
                      <div role="separator" class="dropdown-divider"></div>
                      <a class="dropdown-item" (click)="mostrar_mapa(solicitud, 2)">Un mapa</a>
                    </div>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-file-download"></i></button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" (click)="descargar_archivo(solicitud, 'FILEGDB')">File GDB</a>
                      <div role="separator" class="dropdown-divider"></div>
                      <a class="dropdown-item" (click)="descargar_archivo(solicitud, 'SHP')">Shapefile</a>
                    </div>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-md btn-primary" type="button" (click)="openLoadCatrographyContent(cargar_cartografia, solicitud, ix)" role="button"><i class="fas fa-file-upload"></i> Cargar</button>
                  </td>
              </tr>
          </tbody>
      </table>
    </div>
</div>
<div *ngIf="!solicitudes" class="card">
    <div class="card-body">
        No hay Solicitudes para este usuario.
    </div>
</div>