import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  url_menus = environment.url_menu;


  constructor(
    private httpClient: HttpClient
  ) { }

  consultar_menus() {
    return this.httpClient.get <any>(
      this.url_menus,
    );
  }
}
