import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
// import { Observable } from 'rxjs';
import { AuthService } from "../servicios/auth.service";


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (
    private authService: AuthService,
    private router: Router
  ) { }
  canActivate(): boolean {
    if (this.authService.esta_logueado()) {
      return true;
    }
    this.router.navigate(['login']);

    return false;
  }

}
