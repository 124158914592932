import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComunService } from "../../servicios/comun.service";
import { environment } from 'src/environments/environment';

// OL
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import {fromLonLat} from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';
import Overlay from 'ol/Overlay';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style';
import Point from 'ol/geom/Point';

// Controles
import {ScaleLine, OverviewMap, ZoomToExtent, defaults as defaultControls} from 'ol/control';


// ng-accordion
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';



// Definición de $
const $ = require( "jquery" );
// import * as $ from 'jquery';



@Component({
  selector: 'app-olmapa',
  templateUrl: './olmapa.component.html',
  styleUrls: ['./olmapa.component.css']
})
export class OlmapaComponent implements OnInit, AfterViewInit {
  map: Map;
  popupOverlay;
  localizacion_coordenadas;

  capas = [];
  form_predio: FormGroup;
  form_propietario: FormGroup;

  departamentos;
  municipios;
  tipo_documentos;

  predios;
  propietario;

  button_checked = new Array();

  constructor(
    private formBuilder: FormBuilder,
    private comunService: ComunService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.inicializa_mapa();
    this.form_predio = this.formBuilder.group({
      departamento: '',
      municipio: '',
      id_predio: '',
      matricula_inmobiliaria: '',
      numero_predial: '',
      nupre: '',
      numero_predial_anterior: '',
    });
    this.form_propietario = this.formBuilder.group({
      tipo_documento: '',
      numero_documento: '',
    });
    this.consultar_departamentos();
    this.consultar_tipo_documentos();

    let i = 0;
    while(i < 10) {
      this.button_checked[i] = true;
      i++;
    }

  }

  ngAfterViewInit(): void {
    console.log('Afetr view initi');
    // $('#municipio_select').selectpicker();
  }


  beforeChange($event: NgbPanelChangeEvent) {
    // debugger;
    // let panel_id = $event.panelId;
    // $(`#${panel_id}`).css('overflow','scroll');
  };


  inicializa_mapa() {
    // this.capas = environment.capas;

    //########### Sources ###########
    // let u_manzanas_wms_source = new ImageWMS({
    //   ratio: 1,
    //   url: environment.wms,
    //   params: {
    //     'FORMAT': 'image/png',
    //     'VERSION': '1.1.1',
    //     "STYLES": '',
    //     "LAYERS": 'CatastroMultiproposito:U_MANZANA',
    //     "exceptions": 'application/vnd.ogc.se_inimage',
    //   }
    // });

    // let cc_manzanas_wms_source = new ImageWMS({
    //   ratio: 1,
    //   url: environment.wms,
    //   params: {
    //     'FORMAT': 'image/png',
    //     'VERSION': '1.1.1',
    //     "STYLES": '',
    //     "LAYERS": 'CatastroMultiproposito:CC_MANZANA',
    //     "exceptions": 'application/vnd.ogc.se_inimage',
    //   }
    // });

    // let cc_perimetro_urbano_wms_source = new ImageWMS({
    //   ratio: 1,
    //   url: environment.wms,
    //   params: {
    //     'FORMAT': 'image/png',
    //     'VERSION': '1.1.1',
    //     "STYLES": '',
    //     "LAYERS": 'CatastroMultiproposito:CC_PERIMETROURBANO',
    //     "exceptions": 'application/vnd.ogc.se_inimage',
    //   }
    // });

    // let lc_terreno_wms_source = new ImageWMS({
    //   ratio: 1,
    //   url: environment.wms,
    //   params: {
    //     'FORMAT': 'image/png',
    //     'VERSION': '1.1.1',
    //     "STYLES": '',
    //     "LAYERS": 'CatastroMultiproposito:LC_TERRENO',
    //     "exceptions": 'application/vnd.ogc.se_inimage',
    //   }
    // });

    // LC_CONSTRUCCION

    let mapa_base_catastral_wms_source = new ImageWMS({
      ratio: 1,
      url: environment.wms,
      params: {
        'FORMAT': 'image/png',
        'VERSION': '1.1.1',
        "STYLES": '',
        "LAYERS": environment.mapa_base,
        "exceptions": 'application/vnd.ogc.se_inimage',
      }
    });


    // ########### Layers ###########

    // Capa Mapa Base OSM
    let osm = new TileLayer({
      source: new OSM()
    });
    osm.set('nombre', 'OpenStreetMap');


    // Capas Vectoriales
    // let u_manzanas_layer = new ImageLayer({
    //   source: u_manzanas_wms_source
    // });

    // let cc_manzanas_layer = new ImageLayer({
    //   source: cc_manzanas_wms_source
    // });

    // let cc_perimetro_urbano_layer = new ImageLayer({
    //   source: cc_perimetro_urbano_wms_source
    // });

    // let lc_terreno_layer = new ImageLayer({
    //   source: lc_terreno_wms_source
    // });

    let mapa_base_catastral_layer = new ImageLayer({
      source: mapa_base_catastral_wms_source
    });
    mapa_base_catastral_layer.set('nombre', 'Mapa Base Catastral');


    let view = new View({
      center: fromLonLat([-74.21049066270085, 4.568707509128573]),
      zoom: 13,
      // projection: 'EPSG:4686'
    });


    //########### Geolocalización ###########
    let geolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: view.getProjection(),
    });

    $("#button_location").click(function() {
      if (geolocation.getTracking()) {
        geolocation.setTracking(false);
        positionFeature.setGeometry(null);
        // positionFeature.setStyle(null);
        accuracyFeature.setGeometry(null);
      }else {
        geolocation.setTracking(true);
        // if (sessionStorage.getItem('localizacion_coordenadas')) {
        //let localicacion = sessionStorage.getItem('localizacion_coordenadas');
        let coordinates = geolocation.getPosition();
        view.setCenter(coordinates);
        //view.setCenter(JSON.parse(localicacion));
        // }
      }
    });

    let accuracyFeature = new Feature();
    geolocation.on('change:accuracyGeometry', function () {
      accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
    });

    let positionFeature = new Feature();
    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 8,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );

    geolocation.on('change:position', function () {
      let coordinates = geolocation.getPosition();
      // sessionStorage.setItem('localizacion_coordenadas', JSON.stringify(coordinates))
      positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
      view.setCenter(coordinates);
      // geolocation.setTracking(false);
    });



    // ########### Control overview map ###########
    let overviewMapControl = new OverviewMap({
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      collapsed: false,
    });

    let zoomToExtentControl = new ZoomToExtent({
      extent: [
        -8268507.533091574,
        514409.7191798665,
        -8253009.925606755,
        504109.829618439 ],
    });



    // ########### Control scala ###########
    let scaleLinecontrol = new ScaleLine({
      units: 'metric',
    });



    // ########### MAPA ###########
    // const bogota_lon_lat = fromLonLat([-74.08175, 4.60971]);
    this.map = new Map({
      target: 'map',
      controls: defaultControls().extend([
        overviewMapControl,
        zoomToExtentControl,
        scaleLinecontrol
      ]),
      view: view
    });

    // this.map.addLayer(geolocalizacion_layer);
    let geolocalizacion_layer = new VectorLayer({
      map: this.map,
      source: new VectorSource({
        features: [accuracyFeature, positionFeature],
      }),
    });


    // ########### Legend ###########
    let updateLegend = function (resolution) {
      // let graphicUrl = cc_manzanas_wms_source.getLegendUrl(resolution);
      let graphicUrl = mapa_base_catastral_wms_source.getLegendUrl(resolution);
      let image = $('#legend');
      image.attr("src", graphicUrl);
      // image.src = graphicUrl;
    };

    // Initial legend
    let resolution = this.map.getView().getResolution();
    updateLegend(resolution);

    // Update the legend when the resolution changes
    this.map.getView().on('change:resolution', function (event) {
      let resolution = event.target.getResolution();
      updateLegend(resolution);
    });




    // ########### Add layers to map ###########
    // this.map.addLayer(u_manzanas_layer);
    this.map.addLayer(osm);
    // this.map.addLayer(cc_manzanas_layer);      // => Esta
    // this.map.addLayer(lc_terreno_layer);
    // this.map.addLayer(cc_perimetro_urbano_layer);     // => Esta
    this.map.addLayer(mapa_base_catastral_layer);     // => Esta
    $("#cargando").hide();




    // ########### Pop UP ###########
    let container = $('#popup');
    let content = document.getElementById('popup-content');
    let closer = document.getElementById('popup-closer');

    // Create an overlay to anchor the popup to the map.
    let overlay = new Overlay({
      element: container[0],
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    this.map.addOverlay(overlay);

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };


    // ########### Eventos mapa ###########
    this.map.on('singleclick', function (evt) {
      let contenido_popup = '';
      this.forEachFeatureAtPixel(evt.pixel, function (feat) {
          if('datos_predio' in feat.getProperties()) {
            let datos = feat.get('datos_predio');
            contenido_popup = `
            <table class="table table-bordered  table-hover">
              <tbody>
                <tr>
                  <th>NOMBRE DEPARTAMENTO</th>
                  <td>${datos.nombre_Departamento}</td>
                </tr>
                <tr>
                  <th>nombre_Municipio</th>
                  <td>${datos.nombre_Municipio}</td>
                </tr>
                <tr>
                  <th>codigo_Orip</th>
                  <td>${datos.codigo_Orip}</td>
                </tr>
                <tr>
                  <th>matricula_Inmobiliaria</th>
                  <td>${datos.matricula_Inmobiliaria}</td>
                </tr>
                <tr>
                  <th>numero_Predial</th>
                  <td>${datos.numero_Predial}</td>
                </tr>
                <tr>
                  <th>numero_Predial_Anterior</th>
                  <td>${datos.numero_Predial_Anterior}</td>
                </tr>
                <tr>
                  <th>nupre</th>
                  <td>${datos.nupre}</td>
                </tr>
                <tr>
                  <th>avaluo_Catastral</th>
                  <td>${datos.avaluo_Catastral}</td>
                </tr>
                <tr>
                  <th>tipo_Predio</th>
                  <td>${datos.tipo_Predio}</td>
                </tr>
                <tr>
                  <th>tipo_Descripcion</th>
                  <td>${datos.tipo_Descripcion}</td>
                </tr>
                <tr>
                  <th>condicion_Predio</th>
                  <td>${datos.condicion_Predio}</td>
                </tr>
                <tr>
                  <th>condicion_Descripcion</th>
                  <td>${datos.condicion_Descripcion}</td>
                </tr>
                <tr>
                  <th>nombre_Predio</th>
                  <td>${datos.nombre_Predio}</td>
                </tr>
                <tr>
                  <th>comienzo_Vida_Util_Version</th>
                  <td>${datos.comienzo_Vida_Util_Version}</td>
                </tr>
                <tr>
                  <th>fin_Vida_Util_Version</th>
                  <td>${datos.fin_Vida_Util_Version}</td>
                </tr>
                <tr>
                  <th>estado_Predio</th>
                  <td>${datos.estado_Predio}</td>
                </tr>
                <tr>
                  <th>estado_Desc_Predio</th>
                  <td>${datos.estado_Desc_Predio}</td>
                </tr>
                <tr>
                  <th>area_Registral_M2</th>
                  <td>${datos.area_Registral_M2}</td>
                </tr>
                <tr>
                  <th>destinacion_Economica</th>
                  <td>${datos.destinacion_Economica}</td>
                </tr>
                <tr>
                  <th>nombre_Destinacion</th>
                  <td>${datos.nombre_Destinacion}</td>
                </tr>
                <tr>
                  <th>clase_Suelo</th>
                  <td>${datos.clase_Suelo}</td>
                </tr>
                <tr>
                  <th>nombre_Clase</th>
                  <td>${datos.nombre_Clase}</td>
                </tr>
                <tr>
                  <th>categoria_Suelo</th>
                  <td>${datos.categoria_Suelo}</td>
                </tr>
                <tr>
                  <th>nombre_Categoria</th>
                  <td>${datos.nombre_Categoria}</td>
                </tr>
              </tbody>
            </table>`;
            let coordinate = evt.coordinate;
            content.innerHTML = contenido_popup;
            overlay.setPosition(coordinate);
          }
        },
        {
          hitTolerance: 5,
        }
      );
    });

    this.map.on('rendercomplete', e => {
      let _this = this;
      this.capas = [];
      this.map.getLayers().forEach(function(layer, layer_index) {
        _this.capas.push({nombre: layer.getProperties().nombre});
      });
    });
  }


  capa_boton(capa, i) {
    let _this = this;
    this.map.getLayers().forEach(function(layer, layer_index) {
      if (layer.getProperties()['nombre'] == capa.nombre) {
        layer.setVisible(!layer.getVisible());
        _this.button_checked[i] = layer.getVisible();
      }
    });
  }


  sidebar() {
    if ( $("#mySidebar").is(':visible')) {
      $("#mySidebar").hide(500);
    }
    else {
      $("#mySidebar").show(500);
    }
  }

  mostrar_geojson(data) {
    let geojson = JSON.parse(data.geojson);

    let style_2 = new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 3
      }),
      text: new Text({
        font: '12px Calibri,sans-serif',
        fill: new Fill({
          color: '#000',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3,
        }),
      }),
      fill: new Fill({
        color: [255, 255, 255, 0.5]
      })
    })

    let format = new GeoJSON({
      featureProjection:"EPSG:3857"
    });
    let features = format.readFeatures(geojson);

    let feature_data = {
      nombre_Departamento: data.nombre_Departamento,
      nombre_Municipio: data.nombre_Municipio,
      codigo_Orip: data.codigo_Orip,
      matricula_Inmobiliaria: data.matricula_Inmobiliaria,
      numero_Predial: data.numero_Predial,
      numero_Predial_Anterior: data.numero_Predial_Anterior,
      nupre: data.nupre,
      avaluo_Catastral: data.avaluo_Catastral,
      tipo_Predio: data.tipo_Predio,
      tipo_Descripcion: data.tipo_Descripcion,
      condicion_Predio: data.condicion_Predio,
      condicion_Descripcion: data.condicion_Descripcion,
      nombre_Predio: data.nombre_Predio,
      comienzo_Vida_Util_Version: data.comienzo_Vida_Util_Version,
      fin_Vida_Util_Version: data.fin_Vida_Util_Version,
      estado_Predio: data.estado_Predio,
      estado_Desc_Predio: data.estado_Desc_Predio,
      area_Registral_M2: data.area_Registral_M2,
      destinacion_Economica: data.destinacion_Economica,
      nombre_Destinacion: data.nombre_Destinacion,
      clase_Suelo: data.clase_Suelo,
      nombre_Clase: data.nombre_Clase,
      categoria_Suelo: data.categoria_Suelo,
      nombre_Categoria: data.nombre_Categoria,
    };
    features[0].set('datos_predio', feature_data);
    let vectorSource = new VectorSource({
        features: features
    });

    let vectorLayer = new VectorLayer({
        style: style_2,
        source: vectorSource,
    });
    vectorLayer.set('nombre', data.numero_Predial);

    this.map.addLayer(vectorLayer);
    let extent = vectorSource.getExtent();
    this.map.getView().fit(extent);
  }


  consultar_departamentos() {
    this.comunService.consultar_depatramentos()
      .subscribe(
        res => {
          let departamentos_ordenados = res.recordset.sort((a, b) => a.nombre_Departamento.localeCompare(b.nombre_Departamento));
          this.departamentos = departamentos_ordenados;
        },
        err => {
          if(err.statusText=='Unauthorized') {
            alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
            this.router.navigate(['/login']);
          }
        }
      );
  }

  consultar_municipios(departamento_id: number) {
    $("#cargando").show();
    this.comunService.consultar_municipios(departamento_id)
      .subscribe(
        res => {
          $("#cargando").hide();
          let municipios_ordenados = res.recordset.sort((a, b) => a.nombre_Municipio.localeCompare(b.nombre_Municipio));
          this.municipios = municipios_ordenados;
        },
        err => {
          $("#cargando").hide();
          if(err.statusText=='Unauthorized') {
            alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
            this.router.navigate(['/login']);
          }
        }
      );
  }

  departamentoChange(departamento_id) {
    this.consultar_municipios(departamento_id);
  }


  buscar_por_predio() {
    $("#cargando").show();
    let data = this.form_predio.getRawValue();
    if(!data.departamento) {
      alert('Por favor seleccione un Departamento.')
      return false;
    }
    if(!data.municipio) {
      alert('Por favor seleccione un Municipio.')
      return false;
    }
    if (!data.id_predio && !data.matricula_inmobiliaria && !data.numero_predial && !data.nupre && !data.numero_predial_anterior) {
      alert('Por favor ingrese datos en alguno de los campos: ID Predio, Matricula Inmobiliaria, Número Predial, Nupre o Número Predial Anterior.');
      return false;
    }
    if(!data.id_predio) {
      data.id_predio = 0;
    }
    if (data.nupre) {
      data.nupre = BigInt($("#nupre").val());
    }

    this.comunService.consultar_por_predio(data)
      .subscribe(
        res => {
          $("#cargando").hide();
          this.mostrar_geojson(res.recordset[0])
        },
        err => {
          $("#cargando").hide();
          alert(err.error.mensaje);
        }
      );
  }


  consultar_tipo_documentos() {
    this.comunService.consultar_tipo_documentos()
      .subscribe(
        res => {
          this.tipo_documentos = res.recordset;
        },
        err => {
          if(err.statusText=='Unauthorized') {
            alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
            this.router.navigate(['/login']);
          }
        }
      );
  }


  buscar_por_propietario() {
    $("#cargando").show();
    let data = this.form_propietario.getRawValue();
    if(!data.tipo_documento) {
      alert('Por favor seleccione un Tipo de Documento.')
      return false;
    }
    if(!data.numero_documento) {
      alert('Por favor ingrese un Número de Documento.')
      return false;
    }

    this.comunService.consultar_por_propietario(data)
      .subscribe(
        res => {
          $("#cargando").hide();
          this.predios = res.recordset;
          this.propietario = res.recordset[0].nombre;
        },
        err => {
          $("#cargando").hide();
          if (err.status == 401) {
            alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
            this.router.navigate(['/login']);
          }
          else {
            alert(err.error.mensaje);
          }
        }
      );
  }

  mostrar_ocultar_buscador() {
    let buscador = $("#buscador");
    if (buscador.css('display') == "block") {
        buscador.hide(300);
    } else {
        buscador.show(600);
    }
}

}
