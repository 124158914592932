
<div id="popup1" class="ol-popup">
  <a id="popup-closer1" class="ol-popup-closer"></a>
  <div id="popup-content1" class="ol-popup-content"></div>
</div>
<!-- <div id="popup2" class="ol-popup">
  <a id="popup-closer2" class="ol-popup-closer"></a>
  <div id="popup-content2"></div>
</div> -->

<!-- Modal Error-->
<ng-template #detalles_error let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Error de Validación</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal_validacion" id="modal_body">
    <table class="table">
      <tbody>
        <tr>
          <th scope="row">CAPA</th>
          <td>{{datos_validacion.capa}}</td>
        </tr>
        <tr>
          <th scope="row">ETIQUETA</th>
          <td>{{datos_validacion.etiqueta}}</td>
        </tr>
        <tr>
          <th scope="row">MENSAJE</th>
          <td>{{datos_validacion.mensaje}}</td>
        </tr>
        <tr>
          <th scope="row">T_ID</th>
          <td>{{datos_validacion.t_ID}}</td>
        </tr>
        <tr>
          <th scope="row">T_ID_DELTA</th>
          <td>{{datos_validacion.t_ID_delta}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Aceptar</button>
  </div>
</ng-template>


<!-- Modal Porcesar-->
<ng-template #detalles_procesar let-modal>
  <div class="modal-header">
    <h4 class="modal-title">PROCESO EXITOSO, FLUJO COMPLETO</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal_validacion" id="modal_body">
    <table class="table">
      <tbody>
        <tr>
          <th scope="row">REGISTROS NUEVOS TERRENO</th>
          <td>{{datos_procesar.registros_nuevos_terreno}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS CONSTRUCCIÓN</th>
          <td>{{datos_procesar.registros_nuevos_construccion}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS MANZANA</th>
          <td>{{datos_procesar.registros_nuevos_manzana}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS VEREDA</th>
          <td>{{datos_procesar.registros_nuevos_vereda}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS UNIDAD DE CONSTRUCCIÓN</th>
          <td>{{datos_procesar.registros_nuevos_unidad_construccion}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS SERVIDUMBRE DE TRANSITO</th>
          <td>{{datos_procesar.registros_nuevos_servidumbre}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS PUNTOS LINDERO</th>
          <td>{{datos_procesar.registros_nuevos_puntoLindero}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Aceptar</button>
  </div>
</ng-template>

<div id="cargando" class="spinner-grow" style="width: 2rem; height: 2rem; position: absolute;" role="status">
  <span class="sr-only">Cargando...</span>
</div>

<div class="row no-gutters">
  <div class="col-md-4 offset-md-4">
    <p class="h3 text-center">SOLICITUD {{solicitud.solinume}}</p>
  </div>
</div>
<div class="row no-gutters" style="height: calc(100% - 100px)">
    <div class="col-md-12 ">
      <div id="map1" class="mapa-carga"></div>

      <div class="ol-control ol-sidebar" style="pointer-events: auto;">
        <button id="boton_sidebar" class="btn" (click)="sidebar1()"><i class="fas fa-layer-group"></i></button>
      </div>
     
      <div id="mySidebar1" class="sidebar" >
        <div class="card" id="capas_div">
          <div class="card-body">
            <h4>CAPAS BASE</h4>
            <div *ngFor="let capa of capas; let i = index" class="form-group div_boton_capa" >
              <div class=" d-inline ">
                <input type="checkbox" class="form-check-input" id="button_capa{{i}}" [checked]="button_checked[i]"
                  (click)="capa_boton(capa, i)" >
                <label class="form-check-label" for="button_capa{{i}}">{{capa.nombre}}</label>
              </div>
              <div class=" d-inline ">
                <input type="range" id="slider_capa_{{i}}" class="form-control-range" min="0" max="1" step="0.1"
                  [(ngModel)]="slider_value_array[i]" (change)="change_range($event, capa.nombre)" >
              </div>
            </div>

            <h4>CAPAS ORIGINALES</h4>
            <div *ngFor="let capa of capas1; let i = index" class="form-group div_boton_capa" >
              <div class=" d-inline ">
                <input type="checkbox" class="form-check-input" id="button_capa_1_{{i}}" [checked]="button_checked_1[i]"
                  (click)="capa_boton(capa, i)" >
                <label class="form-check-label" for="button_capa_1_{{i}}">{{capa.nombre}}</label>
              </div>
              <div class=" d-inline ">
                <input type="range" id="slider_capa_{{i}}" class="form-control-range" min="0" max="1" step="0.1"
                  [(ngModel)]="slider_value_array_1[i]" (change)="change_range($event, capa.nombre)" >
              </div>
            </div>

            <h4>CAPAS DELTA</h4>
            <div *ngFor="let capa of capas2; let i = index" class="form-group div_boton_capa" >
              <div class=" d-inline ">
                <input type="checkbox" class="form-check-input" id="button_capa_2_{{i}}" [checked]="button_checked_2[i]"
                  (click)="capa_boton(capa, i)" >
                <label class="form-check-label" for="button_capa_2_{{i}}">{{capa.nombre}}</label>
              </div>
              <div class=" d-inline ">
                <input type="range" id="slider_capa_{{i}}" class="form-control-range" min="0" max="1" step="0.1"
                  [(ngModel)]="slider_value_array_2[i]" (change)="change_range($event, capa.nombre)" >
              </div>
            </div>

            </div>
          </div>
        </div>
      </div>
</div>
<div class="row no-gutters p-2">
  <div class="col-md-2 offset-md-2">
      <button type="button" class="btn btn btn-danger btn-block" [routerLink]="['/carga']" >CANCELAR</button>
  </div>
  <div class="col-md-2 offset-md-1">
      <button type="button" class="btn btn btn-info btn-block" (click)="validar_datos()" >VALIDAR</button>
  </div>
  <div class="col-md-2 offset-md-1">
      <button type="button" class="btn btn btn-success btn-block" (click)="procesar_datos()" [disabled]="!datos_validos">PROCESAR</button>
  </div>
</div>
