
<div id="cargando" class="spinner-grow" style="width: 2rem; height: 2rem; position: absolute;" role="status">
  <span class="sr-only">Loading...</span>
</div>

<div id="popup" class="ol-popup">
    <a id="popup-closer" class="ol-popup-closer"></a>
    <div id="popup-content" class="ol-popup-content"></div>
</div>


<!-- <div id='info'>INFO</div> -->

<div class="row ">
    <!-- BUSCADOR -->

    <div class="panel panel-primary panel-fixed">
      <button (click)="mostrar_ocultar_buscador()" class="btn btn-outline-info"><i class="fas fa-search"></i></button>
    </div>

    <div id="main" class="col-md-12">

      <div id="buscador" >
        <ngb-accordion [closeOthers]="true" activeIds="static-1" (panelChange)="beforeChange($event)">
          <ngb-panel id="static-1" title="BUSQUEDA POR PREDIO">
            <ng-template ngbPanelContent>

              <form [formGroup]="form_predio" (submit)="buscar_por_predio()" style="height: 55vh; overflow-y: scroll; ">
                <div class="form-group row">
                  <div class="col-12">
                    <select id="departamento_select" (ngModelChange)="departamentoChange($event)" formControlName="departamento" class="form-control form-control-sm" data-live-search="true" required>
                      <option value="" selected disabled hidden>Departamento</option>
                      <option *ngFor="let departamento of departamentos;" [value]="departamento.codigo_Departamento">{{departamento.nombre_Departamento}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row" >
                  <div class="col-12">
                    <select id="municipio_select" class="form-control form-control-sm" formControlName="municipio"  data-live-search="true" required>
                      <option value="" selected disabled hidden>Municipio</option>
                      <option *ngFor="let municipio of municipios;" [value]="municipio.codigo_Municipio">{{municipio.nombre_Municipio}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" >
                  <div class="col-12">
                    <input type="number" class="form-control form-control-sm" id="id_predio" formControlName="id_predio" placeholder="ID del Predio">
                  </div>
                </div>
                <div class="form-group row" >
                  <div class="col-12">
                    <input type="text" class="form-control form-control-sm" id="matricula_inmobiliaria" formControlName="matricula_inmobiliaria" placeholder="Matricula Inmobiliaria">
                  </div>
                </div>
                <div class="form-group row" >
                  <div class="col-12">
                    <input type="text" class="form-control form-control-sm" id="numero_predial" formControlName="numero_predial" placeholder="Número Predial">
                  </div>
                </div>
                <div class="form-group row" >
                  <div class="col-12">
                    <input type="number" class="form-control form-control-sm" id="nupre" formControlName="nupre" placeholder="Número de Predio">
                  </div>
                </div>
                <div class="form-group row" >
                  <div class="col-12">
                    <input type="number" class="form-control form-control-sm" id="numero_predial_anterior" formControlName="numero_predial_anterior" placeholder="Número Predial Anterior">
                  </div>
                </div>

                <button class="btn btn_buscar btn-primary" type="submit">Buscar</button>
              </form>

            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span>BUSQUEDA POR PROPIETARIO</span>
            </ng-template>
            <ng-template ngbPanelContent>

              <form [formGroup]="form_propietario" (submit)="buscar_por_propietario()" >
                <div class="form-group row">
                  <div class="col-12">
                    <select id="tipo_documento_select" formControlName="tipo_documento" class="form-control  form-control-sm" data-live-search="true" required>
                      <option value="" selected disabled hidden>Tipo de Documento</option>
                      <option *ngFor="let tipo_documento of tipo_documentos;" [value]="tipo_documento.t_ID">{{tipo_documento.dispname}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row" >
                  <div class="col-12">
                    <input type="number" class="form-control form-control-sm" id="numero_documento" formControlName="numero_documento" placeholder="Número de identificación" required>
                  </div>
                </div>

                <button class="btn btn-sm btn_buscar btn-primary" type="submit">Buscar</button>
              </form>
              <div id="div_resultados" *ngIf="predios">
                Porpietario: {{ propietario}}
                <div *ngFor="let predio of predios" class="card">
                  <div class="card-body">
                    <h5 class="card-title">Predio {{predio.id_Predio}}</h5>
                    <p class="card-text">
                      Porpietario: {{predio.nombre}}
                    </p>
                    <a (click)="mostrar_geojson(predio)" class="btn btn-sm btn-outline-success float-right">Ver <i class="fas fa-map-marked-alt"></i></a>
                  </div>
                </div>
              </div>

            </ng-template>
          </ngb-panel>
          <!-- <ngb-panel id="static-3" title="BUSQUEDA POR..." [disabled]="true">
            <ng-template ngbPanelContent>

            </ng-template>
          </ngb-panel> -->
        </ngb-accordion>
      </div>

      <!-- MAPA -->
      <div id="map" class="map" ></div>

      <div class="ol-control ol-sidebar" style="pointer-events: auto;">
        <button id="boton_sidebar" class="btn" (click)="sidebar()"><i class="fas fa-layer-group"></i></button>
      </div>

      <div class="ol-control ol-locate" style="pointer-events: auto;">
        <button id="button_location" class="btn"><i class="fas fa-crosshairs"></i></button>
      </div>

      <div id="mySidebar" class="sidebar" >
        <div class="card">
          <div class="card-body">
            <div>
              LEYENDA:
            </div>
            <div>
              <img id="legend"/>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div>
              CAPAS:
            </div>
            <div>
              <!-- <button *ngFor="let capa of capas; let i = index" type="button" class="btn btn-outline-secondary my-1"
              (click)="capa_boton(capa)" >{{capa.nombre}}</button> -->
              <div *ngFor="let capa of capas; let i = index" class="div_boton_capa" >
                <input type="checkbox" class="form-check-input" value="true" id="button_capa_{{i}}" [checked]="button_checked[i]"
                  (click)="capa_boton(capa, i)" >
                <label class="form-check-label" for="button_capa_{{i}}">{{capa.nombre}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>





