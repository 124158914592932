import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComunService } from 'src/app/servicios/comun.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

const $ = require( "jquery" );

@Component({
  selector: 'app-carga',
  templateUrl: './carga.component.html',
  styleUrls: ['./carga.component.css']
})
export class CargaComponent implements OnInit, AfterViewInit {
  solicitudes;
  solicitud_actual;
  ix;
  tipoArchivoCarga;
  modalUpload: NgbModalRef;

  constructor(
    private comunService: ComunService,
    private router: Router,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) { }

  ngAfterViewInit(): void{
    console.log('--ngAfterViewInit');

  }

  ngOnInit(): void {
    $("#cargando").show();
    console.log('--ngOnInit');

    this.comunService.consultar_solicitudes()
    .subscribe(
      res => {
        $("#cargando").hide();
        this.solicitudes = res.recordset;
        for (let id in this.solicitudes) {
          this.solicitudes[id].solifech = this.datePipe.transform(this.solicitudes[id].solifech, 'yyyy-MM-dd');
        }

      },
      err => {
        $("#cargando").hide();
        if(err.statusText=='Unauthorized') {
          alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
          this.router.navigate(['/login']);
        }
        else {
          let mns_err = err.error.mensaje ? err.error.mensaje : err.error.error;
          alert(mns_err);
        }
      }
    );
  }

  openDetailedContent(detalles_solicitud, solicitud) {
    this.solicitud_actual = solicitud;
    this.modalService.open(detalles_solicitud, { scrollable: true });
  }

  mostrar_nombre(evento) {
    let archivos = evento.target.files;
    let nombres_html = '<table class="table table-bordered">';
    let contador = 0;
    for(let a of archivos) {
      contador += 1 ;
      nombres_html += `<tr><td>${a.name}</td></tr>`;
    }
    nombres_html += '</table>';
    let id_label = 'label_' + evento.target.id;
    let id_nombres = 'nombres_archivos_' + evento.target.id;

    $(`#${id_label}`).html(`${contador} Archivos`);
    $(`#${id_nombres}`).html(nombres_html);
  }

  descargar_archivo(solicitud, tipoArchivo) {
    $("#cargando").show();

    this.comunService.descargar_cartografia(solicitud, tipoArchivo)
      .subscribe(
        res => {
          $("#cargando").hide();
          let archivo_b64 = res.recordset[0]['archivoFinal'];
          let link = document.createElement("a");
          document.body.appendChild(link);
          link.setAttribute("type", "hidden");
          link.href = "data:text/plain;base64," + archivo_b64;

          if(tipoArchivo == "FILEGDB"){
            link.download = `data_${solicitud.solinume}_fgdb.gdb.zip`;
          }else{
            link.download = `data_${solicitud.solinume}_shp.zip`;
          }

          link.click();
          document.body.removeChild(link);
        },
        err => {
          $("#cargando").hide();
          if(err.statusText=='Unauthorized') {
            alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
            this.router.navigate(['/login']);
          }
          let mns_err = err.error.mensaje ? err.error.mensaje : err.error.error;
          alert(mns_err);
        }
      );
  }

  descargar_fgdb(res){
    let archivo_b64 = res.recordset[0]['archivoFinal'];
    let link = document.createElement("a");
    document.body.appendChild(link);
    link.setAttribute("type", "hidden");
    link.href = "data:text/plain;base64," + archivo_b64;    // res;
    link.download = "data.gdb.zip";
    link.click();
    document.body.removeChild(link);
  }

  descargar_shapefile(res){
    let archivo_b64 = res.recordset[0]['archivoFinal'];
    let link = document.createElement("a");
    document.body.appendChild(link);
    link.setAttribute("type", "hidden");
    link.href = "data:text/plain;base64," + archivo_b64;    // res;
    link.download = "data.gdb.zip";
    link.click();
    document.body.removeChild(link);
  }


  dataURLToBlob(dataURL) {
    let BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        let parts = dataURL.split(',');
        let contentType = parts[0].split(':')[1];
        let raw = parts[1];

        return new Blob([raw], {type: contentType});
    }
    else {
        let parts = dataURL.split(BASE64_MARKER);
        let contentType = parts[0].split(':')[1];
        // return parts[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;

        let uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: contentType});
    }
  }


  validar_nombre_archivos(archivos, num_solicitud) {
    let nombres_mal = [];

    for(let archivo of archivos) {
      let nombre_archivo = archivo.name;
      // let re1 = /\b\d+\b/g;   // Cualquier caracter numerico sin importar longitud.
      let re2 = /\b\w+\b/g;  // Cualquier caracter alfanumerico sin importar long. usar D si debe ser no numerico
      let re3 = /\b\d{10}\b/g;  // 10 digitos
      let nombre_ext = nombre_archivo.split('.');
      let ext = nombre_ext[1];
      let partes = nombre_ext[0].split('_');
      let coincide = true;
      if (ext.toLowerCase() != 'zip') {
        coincide = false;
      }
      if (partes.length == 2) {
        if (!partes[0].match(re2) || !partes[1].match(re3)) {     // || !partes[0].match(re1)
          coincide = false;
        }
        if (partes[1].replace(/^0+/, '')  != num_solicitud) {
          coincide = false;
        }
      }
      else {
        coincide = false;
      }

      if (!coincide) {
        nombres_mal.push(nombre_archivo);
      }
    }

    if(nombres_mal.length > 0) {
      let mensaje = `El archivo no es ZIP, o el formato del nombre: [${nombres_mal.join(', ')}] no es válido, el nombre se debe componer de 2 partes separadas por "_", la primer parte es alfanumérica y la segunda numérica de 10 caracteres y debe coincidir con el número de la solicitud.`;
      alert(mensaje);
      return false;
    }
    else {
      return true;
    }
  }


  mostrar_mapa(solicitud, tipo_mapa) {
    sessionStorage.setItem('solicitud', JSON.stringify(solicitud));
    if (tipo_mapa == 1) {
      this.router.navigate(['carga_mapa']);
    }
    else if (tipo_mapa == 2) {
      this.router.navigate(['carga_mapa_unico']);
    }
  }

  openLoadCatrographyContent(detalles_solicitud, solicitud, ix) {
    this.solicitud_actual = solicitud;
    this.ix = ix;
    this.modalUpload = this.modalService.open(detalles_solicitud);    
    this.tipoArchivoCarga = "FILEGDB"; //Se establece apenas se carga el modal
  }

  setTipoArchivoCarga(tipoArchivo){
    if(tipoArchivo){
      this.tipoArchivoCarga = tipoArchivo;
    }
  }
  
  enviar_archivo(solicitud, ix, tipo_mapa) {    
    let input_label = `elementos_input_${ix}`;
    let archivos = $(`#${input_label}`).prop('files');

    if (!archivos.length) {
      alert('No hay archivos para subir.');
      return;
    }
    
    $("#cargando").show();
    let validacion = true;

    if (!validacion) {
      $("#cargando").hide();
      this.limpiar_archivo(ix);
      return false;
    }

    let promesasArchivos = $.makeArray(archivos).map((archivo)=>{
      return this.file_reader(archivo);
    });

    Promise.all(promesasArchivos).then(archivos => {
      let lista_data_archivos = archivos.map((archivo: any) => {
        return {
          "nombreArchivo": archivo.name,
          "contenidoArchivo": archivo.base64,
          "numeroSolicitud": parseInt(solicitud.solinume),
          "mutacion": parseInt(solicitud.mutacion),
          "responsable_CUI": null
        }
      });

      this.comunService.subir_cartografia(lista_data_archivos, solicitud.solinume, this.tipoArchivoCarga).subscribe(res => {
          $("#cargando").hide();
          this.modalUpload.close();

          let resultado_subir = res.recordset;
          let mensajes = '';
          let correcto = true;
          for (let res_a of resultado_subir) {
            if (res_a.estado == 0) {
              mensajes += `Problema con el archivo: ${res_a.nombreArchivo}, `+ res_a.observacion + '\n';
              correcto = false;
            }
            if (res_a.estado == 2) {
              mensajes += `Advertencia con el archivo: ${res_a.nombreArchivo}, `+ res_a.observacion + '\n';
            }
          }

          if (mensajes == '') {
            alert('Subieron todos los archivos correctamente.');
          } else {
            alert(mensajes);
          }

          if (correcto) {
            this.limpiar_archivo(ix);
            sessionStorage.setItem('solicitud', JSON.stringify(solicitud));

            if (tipo_mapa == 1) {
              this.router.navigate(['carga_mapa']);
            } else if (tipo_mapa == 2) {
              this.router.navigate(['carga_mapa_unico']);
            }
          }
        },
        err => {
          $("#cargando").hide();

          if(err.statusText=='Unauthorized') {
            alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
            this.router.navigate(['/login']);
          } else {
            let mns_err = err.error.mensaje ? err.error.mensaje : err.error.error;
            alert(mns_err);
          }
        }
      );        
    }, (error)=>{
      alert('Error: ' + JSON.stringify(error, null, 4));
    }).catch(error => {
      alert('Error: ' + JSON.stringify(error, null, 4));
    });
  }

  file_reader(archivo){
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = ()=>{
        let resultado:any = reader.result;
        let b64 = '';
        if (resultado.indexOf(';base64,') == -1) {
            let parts = resultado.split(',');
            b64 = parts[1];
        }
        else {
            let parts = resultado.split(';base64,');
            b64 = parts[1];
        }
        
        resolve({"base64": b64, "name": archivo.name});
      }
      reader.onerror = reject;
      
      reader.readAsDataURL(archivo);
    });
  }


  limpiar_archivo(ix) {
    let input_label = `elementos_input_${ix}`;
    $(`#${input_label}`).val(null);

    let id_label = 'label_elementos_input_' + ix;
    let id_nombres = 'nombres_archivos_elementos_input_' + ix;

    $(`#${id_label}`).html('Solo Archivos ZIP');
    $(`#${id_nombres}`).html('');
  }

}
