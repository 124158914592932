
<main class="form-signin">
    <form [formGroup]="form" (submit)="enviar()" >
        <h1 class="h3 mb-3 fw-normal">Inicio de Sesión</h1>

        <div class="form-floating">
        <input formControlName="login" type="text" class="form-control" id="floatingInput" placeholder="usuario">
        <label for="floatingInput">Usuario</label>
        </div>
        <div class="form-floating">
        <input formControlName="password" type="password" class="form-control" id="floatingPassword" placeholder="Password">
        <label for="floatingPassword">Contraseña</label>
        </div>

        <button class="w-100 btn btn-lg btn-primary" type="submit">Ingresar</button>
    </form>
</main>