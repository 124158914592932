<nav class="navbar navbar-expand-lg navbar-dark bg-dark py-0">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">{{nombre_aplicacion}}</a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mr-auto mb-2 mb-md-0">
          <li *ngFor="let menu of menus;" class="nav-item">
            <a routerLink="{{menu.funcobje}}" class="nav-link" routerLinkActive="active" >{{menu.funcionalidad}}</a>
          </li>
      </ul>

      <ul class="navbar-nav">
        <ng-container *ngIf="!authService.esta_logueado(); else logueado">
          <li class="nav-item">
            <a routerLink="/login" class="nav-link"  routerLinkActive="active" >Iniciar Sesión</a>
          </li>
        </ng-container>
        <ng-template #logueado>
          <!-- Dropdown -->
          <li class="nav-item dropdown" dropdown>
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>Bienvenido {{ authService.nombre_usuario_logueado ? authService.nombre_usuario_logueado : nombre_usuario }} <i class="fas fa-caret-down"></i></a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu" >
              <div class="navbar-login">
                <div class="row">
                    <div class="col-lg-4">
                        <p class="text-center">
                          <i class="far fa-user icon-size ml-2"></i>
                        </p>
                    </div>
                    <div class="col-lg-8">
                        <p class="text-left"><strong>{{ authService.nombre_usuario_logueado ? authService.nombre_usuario_logueado : nombre_usuario }} {{ authService.apellido_usuario_logueado ? authService.apellido_usuario_logueado : apellido_usuario}}</strong></p>
                    </div>
                </div>
            </div>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item waves-light" mdbWavesEffect style="cursor: pointer;" (click)="authService.logout()">Cerrar Sesión</a>
            </div>
          </li>

          <ul class="dropdown-menu">
            <li>
                <div class="navbar-login">
                    <div class="row">
                        <div class="col-lg-4">
                            <p class="text-center">
                                <span class="glyphicon glyphicon-user icon-size"></span>
                            </p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-left"><strong>Mahesh</strong></p>
                            <p class="text-left small">justdemo@gmail.com</p>
                            <p class="text-left">
                                <a href="#" class="btn btn-primary btn-block btn-sm">Logout</a>
                            </p>
                        </div>
                    </div>
                </div>
            </li>
            <li class="divider"></li>
            <li>
                <div class="navbar-login navbar-login-session">
                    <div class="row">
                        <div class="col-lg-12">
                            <p>
                              <a href="#" class="btn btn-primary btn-block">My Profile</a>
                                <a href="#" class="btn btn-danger btn-block">Change Password</a>
                            </p>
                        </div>
                    </div>
                </div>
            </li>
          </ul>

        </ng-template>
      </ul>
    </div>
  </div>
</nav>
