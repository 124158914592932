import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OlmapaComponent } from './componentes/olmapa/olmapa.component';
import { NavComponent } from './componentes/nav/nav.component';
import { LoginComponent } from './componentes/login/login.component';
import { HomeComponent } from './componentes/home/home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from "./guards/auth.guard";
import { TokenInterceptorService } from "./servicios/token-interceptor.service";
import { CargaComponent } from './componentes/carga/carga.component';
import { CargaMapaComponent } from './componentes/carga-mapa/carga-mapa.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DatePipe } from '@angular/common';
import { CargaMapa2Component } from './componentes/carga-mapa2/carga-mapa2.component';

@NgModule({
  declarations: [
    AppComponent,
    OlmapaComponent,
    NavComponent,
    LoginComponent,
    HomeComponent,
    CargaComponent,
    CargaMapaComponent,
    CargaMapa2Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
