
<div id="popup1" class="ol-popup">
  <a id="popup-closer1" class="ol-popup-closer"></a>
  <div id="popup-content1" class="ol-popup-content" ></div>
</div>
<div id="popup2" class="ol-popup">
  <a id="popup-closer2" class="ol-popup-closer"></a>
  <div id="popup-content2" class="ol-popup-content" ></div>
</div>

<!-- Modal Error validación-->
<ng-template #detalles_error let-modal >
  <div class="modal-header">
    <h4 class="modal-title">Error de Validación</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal_validacion" id="modal_body">
    <table class="table">
      <tbody>
        <tr>
          <th scope="row">CAPA</th>
          <td>{{datos_validacion.capa}}</td>
        </tr>
        <tr>
          <th scope="row">ETIQUETA</th>
          <td>{{datos_validacion.etiqueta}}</td>
        </tr>
        <tr>
          <th scope="row">MENSAJE</th>
          <td>{{datos_validacion.mensaje}}</td>
        </tr>
        <tr>
          <th scope="row">T_ID</th>
          <td>{{datos_validacion.t_ID}}</td>
        </tr>
        <tr>
          <th scope="row">T_ID_DELTA</th>
          <td>{{datos_validacion.t_ID_delta}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Aceptar</button>
  </div>
</ng-template>


<!-- Modal Porcesar-->
<ng-template #detalles_procesar let-modal>
  <div class="modal-header">
    <h4 class="modal-title">PROCESO EXITOSO, FLUJO COMPLETO</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal_validacion" id="modal_body">
    <table class="table">
      <tbody>
        <tr>
          <th scope="row">REGISTROS NUEVOS TERRENO</th>
          <td>{{datos_procesar.registros_nuevos_terreno}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS CONSTRUCCIÓN</th>
          <td>{{datos_procesar.registros_nuevos_construccion}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS MANZANA</th>
          <td>{{datos_procesar.registros_nuevos_manzana}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS VEREDA</th>
          <td>{{datos_procesar.registros_nuevos_vereda}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS UNIDAD DE CONSTRUCCIÓN</th>
          <td>{{datos_procesar.registros_nuevos_unidad_construccion}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS SERVIDUMBRE DE TRANSITO</th>
          <td>{{datos_procesar.registros_nuevos_servidumbre}}</td>
        </tr>
        <tr>
          <th scope="row">REGISTROS NUEVOS PUNTOS LINDERO</th>
          <td>{{datos_procesar.registros_nuevos_puntoLindero}}</td>
        </tr>
        <tr>
          <th scope="row">ERRORES EN ASOCIACION DE UNIDADES Y CONSTRUCICONES</th>
          <td>{{datos_procesar.registos_errores_asociacion}}</td>
        </tr>
        <tr>
          <th scope="row">ERRORES EN FILEGDB EXISTEN REFERENCIAS A PREDIOS QUE NO HACEN PARTE DE LA SOLICITUD</th>
          <td>{{datos_procesar.registos_errores_otros}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Aceptar</button>
  </div>
</ng-template>



<div id="cargando" class="spinner-grow" style="width: 2rem; height: 2rem; position: absolute;" role="status">
  <span class="sr-only">Loading...</span>
</div>

<div class="row no-gutters">
  <div class="col-md-4" style="padding-left: 17%;">
    <table  style="height: 100%;">
      <tbody>
        <tr>
          <td class="align-bottom"><h4>ORIGINAL</h4></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-4">
    <p class="h3 text-center">SOLICITUD {{solicitud.solinume}}</p>
  </div>
  <div class="col-md-4" style="padding-left: 10%;">
    <table style="height: 100%;">
      <tbody>
        <tr>
          <td class="align-bottom"><h4>EDITADO</h4></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row no-gutters" style="height: calc(100% - 100px)">
    <div class="col-md-6 pr-2">
      <div id="mapOriginal" class="mapa-carga" ></div>

      <div class="ol-control ol-sidebar" style="pointer-events: auto;">
        <button id="boton_sidebar" class="btn" (click)="sidebar1()"><i class="fas fa-layer-group"></i></button>
      </div>
      <div id="mySidebar1" class="sidebar" >
        <div class="card">
          <div class="card-body">
            <div>
              CAPAS:
            </div>
            <div>
              <div *ngFor="let capa of capas1; let i = index" class="div_boton_capa" >
                <input type="checkbox" class="form-check-input" value="true" id="button_capa_1_{{i}}" [checked]="button_checked1[i]"
                  (click)="capa_boton1(capa, i)" >
                <label class="form-check-label" for="button_capa_1_{{i}}">{{capa.nombre}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div id="mapDelta" class="mapa-carga" ></div>

      <div class="ol-control ol-sidebar" style="pointer-events: auto;">
        <button id="boton_sidebar" class="btn" (click)="sidebar2()"><i class="fas fa-layer-group"></i></button>
      </div>
      <div id="mySidebar2" class="sidebar" >
        <div class="card">
          <div class="card-body">
            <div>
              CAPAS:
            </div>
            <div>
              <!-- <button *ngFor="let capa of capas2; let i = index" type="button" class="btn btn-outline-secondary my-1" [class.active]="button_class_array2[i]"
              (click)="capa_boton2(capa, i)" >{{capa.nombre}}</button> -->
              <div *ngFor="let capa of capas2; let i = index" class="div_boton_capa" >
                <input type="checkbox" class="form-check-input" value="true" id="button_capa_2_{{i}}" [checked]="button_checked2[i]"
                  (click)="capa_boton2(capa, i)" >
                <label class="form-check-label" for="button_capa_2_{{i}}">{{capa.nombre}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="row no-gutters">
    <div class="col-md-6 text-center pr-2 pt-2 pb-2">
        <div class="d-inline-block p-2 bg-danger text-white">Manzana</div>
        <div class="d-inline-block p-2 bg-warning text-white">Terreno</div>
        <div class="d-inline-block p-2 bg-primary text-white">Construcción</div>
        <div class="d-inline-block p-2 bg-puntolindero ">Punto Lindero</div>
        <div class="d-inline-block p-2 bg-undconst ">Und. Construcción</div>
        <div class="d-inline-block p-2 bg-servidumbre">Servidumbre</div>
    </div>
    <div class="col-md-6 pt-2 pb-2">
      <div class="row no-gutters">
        <div class="col-md-4">
          <button type="button" class="btn btn btn-danger p-2 btn-block" [routerLink]="['/carga']" >CANCELAR</button>
        </div>
        <div class="col-md-4">
            <button type="button" class="btn btn btn-info p-2 btn-block" (click)="validar_datos()" >VALIDAR</button>
        </div>
        <div class="col-md-4">
            <button type="button" class="btn btn btn-success p-2 btn-block" (click)="procesar_datos()" [disabled]="!datos_validos">PROCESAR</button>
        </div>
      </div>
    </div>
</div>
