import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthService } from "./servicios/auth.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = environment.titulo;    // 'Catastro Multiproposito';
  wms = environment.wms;
  url_api = environment.url_api;
  url_menu = environment.url_menu;
  
  constructor (
    private authService: AuthService
  ) { }

}
