import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComunService } from 'src/app/servicios/comun.service';
import { MenuService } from 'src/app/servicios/menu.service';
import { AuthService } from "../../servicios/auth.service";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  menus;
  nombre_usuario;
  apellido_usuario;
  nombre_aplicacion;
  private mostrar_inicial_sesion = false;

  constructor(
    public authService: AuthService,
    private comunService: ComunService,
    private menuService: MenuService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.consultar_menus();
    this.nombre_usuario = sessionStorage.getItem('nombre_usuario');
    this.apellido_usuario = sessionStorage.getItem('apellido_usuario');
    this.nombre_aplicacion = environment.titulo;
  }

  consultar_menus() {
    this.menuService.consultar_menus()
      .subscribe(
        res => {
          this.menus = res.items;
        },
        err => {
          if(err.statusText=='Unauthorized') {
            alert('Su sesión ha caducado, por favor inicie sesión nuevamente.')
            this.router.navigate(['/login']);
          }
        }
      );
  }

}
